import React from "react";

export default function Home() {
    return (
        <div className="mt-14 container max-w-5xl mx-auto">
            <div className="flex justify-center items-center flex-wrap gap-12">
                <div>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/nae-black-logo.svg"
                        }
                        className="logo-desktop"
                        alt="Nord Anglia Education logo stacked"
                        width="150"
                        lazyload="false"
                    ></img>
                </div>
                <div className="md:ml-20">
                    <img
                        src={process.env.PUBLIC_URL + "/bc-logo.png"}
                        className="logo-desktop"
                        alt="BC logo"
                        width="180"
                        lazyload="false"
                    ></img>
                </div>
            </div>

            <h1 className="font-montserrat mt-14 text-4xl mb-4 font-bold">
                {" "}
                Nord Anglia Education Metacognition Study
            </h1>

            <div className="font-dmsans p-8 text-left">
                <p className="mb-4">
                    Recognizing the changing nature of global education,{" "}
                    <a href="https://www.google.com/url?q=https://www.nordangliaeducation.com/&sa=D&source=editors&ust=1698685696721357&usg=AOvVaw0Nv1shM6cUraasEAxjksgt">
                        Nord Anglia Education
                    </a>
                    <sup>*</sup> (NAE) has developed the Metacognition Project:
                    a pedagogy-led ed-tech initiative designed to support
                    student progress in metacognition and NAE’s own Learner
                    Ambitions. Seeking to unlock the potential of metacognition
                    for its students, the Metacognition Project integrates a
                    technology-enhanced and skills-based approach that aims to
                    support students in achieving personal, social and academic
                    success.
                </p>
                <p className="mb-4">
                    The NAE Metacognition Project involves four component parts:
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="p-4 border border-gray-200 rounded-lg">
                        <h3 className="font-semibold mb-2">Component 1</h3>
                        <p>
                            Teacher use of a digital platform to access
                            Metacognition Project resources, deliver lessons and
                            collect and respond to formative assessment data in
                            real time.
                        </p>
                    </div>
                    <div className="p-4 border border-gray-200 rounded-lg">
                        <h3 className="font-semibold mb-2">Component 2</h3>
                        <p>
                            Emphasis on Harvard Project Zero Thinking Routines
                            to develop student growth across Learner Ambitions
                            and promote a metacognitive approach to learning.
                        </p>
                    </div>
                    <div className="p-4 border border-gray-200 rounded-lg">
                        <h3 className="font-semibold mb-2">Component 3</h3>
                        <p>
                            A metacognitive goal-setting sequence that prompts
                            student reflection on development and progress in
                            the Learner Ambitions.
                        </p>
                    </div>
                    <div className="p-4 border border-gray-200 rounded-lg">
                        <h3 className="font-semibold mb-2">Component 4</h3>
                        <p>
                            A Learner Portfolio where students collect evidence
                            of their skill development, and reflect on their
                            growth in skills and metacognition.
                        </p>
                    </div>
                </div>

                <p className="my-4">
                    In support of the Metacognition Project’s deployment across
                    27 schools in 5 global regions, NAE is pleased to be
                    collaborating with Boston College researchers on a dynamic
                    2-year research and evaluation partnership.
                </p>
                <video className="w-full rounded-lg shadow-lg mt-12" controls poster={process.env.PUBLIC_URL + "/videos/video-teaser.png"}>
                    <source src={process.env.PUBLIC_URL + "/videos/METACOGNITION PARENT ANIMATION.mp4"} type="video/mp4"/>
                        Your browser does not support the video tag.
                </video>
                <section className="my-20">
                    <h2 className="text-3xl text-[#003057] font-semibold text-center mb-4">
                        Research Questions
                    </h2>
                    <hr className="w-1/2 border-t-4 border-[#003057] mx-auto my-4" />

                    <p className="mb-4">
                        The NAE Metacognition Study aims to answer four key
                        research questions:
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Question 1</h3>
                            <p>
                                What is the effectiveness of the Metacognition
                                Project in promoting metacognitive growth?
                            </p>
                        </div>
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Question 2</h3>
                            <p>
                                Is there a relationship between metacognitive
                                growth and student outcomes and wellbeing?
                            </p>
                        </div>
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Question 3</h3>
                            <p>
                                What are the key factors that contribute to the
                                development of metacognitive skills in learners,
                                and how do these factors influence student
                                learning outcomes and promote academic success
                                and personal wellbeing?
                            </p>
                        </div>
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Question 4</h3>
                            <p>
                                What are the factors of implementation that
                                impact on the success of delivery of the
                                metacognitive approach in schools and
                                classrooms?
                            </p>
                        </div>
                    </div>
                </section>

                <section className="my-20">
                    <h2 className="text-3xl text-[#003057] font-semibold text-center mb-4">
                        Research Methods
                    </h2>
                    <hr className="w-1/2 border-t-4 border-[#003057] mx-auto my-4" />

                    <p className="mb-4">
                        The NAE Metacognition Study employs a mixed methods
                        research study design using both qualitative and
                        quantitative data collection tools. Over the 2-year
                        collaboration, relevant data is captured across
                        participating schools through:
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Method 1</h3>
                            <p>
                                School engagement with the Learner Ambition and
                                metacognitive resources stored in digital tools
                            </p>
                        </div>
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Method 2</h3>
                            <p>
                                Student use of the Learner Portfolio showing
                                evidence of their progress in the Learner
                                Ambitions and core aspects of metacognition
                            </p>
                        </div>
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Method 3</h3>
                            <p>
                                Teacher and student participation in surveys and
                                focus groups across the 2 years of the project
                            </p>
                        </div>
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Method 4</h3>
                            <p>Teacher-led action research projects</p>
                        </div>
                    </div>
                    <p className="mt-4">
                        All research and reflection activities aim to support
                        schools in creating metacognitive cultures of learning
                        in their classrooms, using research-informed resources
                        to enhance student success.
                    </p>
                </section>

                <section className="my-20">
                    <h2 className="text-3xl text-[#003057] font-semibold text-center mb-4">
                        Aims and objectives
                    </h2>
                    <hr className="w-1/2 border-t-4 border-[#003057] mx-auto my-4" />

                    <p className="mb-4">
                        The research and evaluation partnership aims to:
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Aim 1</h3>
                            <p>
                                Measure the growth of different elements of
                                metacognition in students over an academic year.
                            </p>
                        </div>
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Aim 2</h3>
                            <p>
                                Examine the relationship between the growth of
                                each element of metacognition and academic
                                outcomes and student wellbeing.
                            </p>
                        </div>
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Aim 3</h3>
                            <p>
                                Investigate the factors that contribute to the
                                development of metacognitive skills in students.
                            </p>
                        </div>
                        <div className="p-4 border border-gray-200 rounded-lg">
                            <h3 className="font-semibold mb-2">Aim 4</h3>
                            <p>
                                Support the implementation of the Metacognition
                                Project through formative collection and use of
                                evaluation data.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="my-20">
                    <h2 className="text-3xl text-[#003057] font-semibold text-center mb-4">
                        Timelines
                    </h2>
                    <hr className="w-1/2 border-t-4 border-[#003057] mx-auto my-4" />
                    <p className="mb-4">
                        Approximate data collection timeline for Year 1 school
                        participants (2023/2024):
                    </p>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/images/timeline-2023-2024.png"
                        }
                        alt="timeline"
                        className="mt-4 mx-auto w-11/12 rounded-md border-2"
                    />
                    <p className="mt-4">
                        Approximate data collection timeline for Year 2 school
                        participants (2024/2025):
                    </p>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/images/timeline-2024-2025.png"
                        }
                        alt="timeline"
                        className="mt-4 mx-auto w-11/12 rounded-md border-2"
                    />
                    <p className="mt-4">
                        <em>
                            <sup>*</sup> Nord Anglia Education is one of the
                            world’s leading international education
                            organisations with over 80 independent day and
                            boarding schools in more than 30 countries.
                        </em>
                    </p>
                </section>
            </div>
        </div>
    );
}
