import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import Team from "pages/Team";
import Navbar from "components/Navbar";
function App() {
    return (
        <div className="App">
            <header>
                <Navbar />
            </header>
            <main className="container mx-auto px-4 mt-32">
                <Routes>
                    <Route path="/team" element={<Team />} />
                    <Route exact path="/" element={<Home />} />
                </Routes>
            </main>
            <footer className="bg-[#003057] mt-10 py-4">
                <div className="container mx-auto text-center">
                    <a
                        href="https://www.nordangliaeducation.com/privacy-policy"
                        className="text-white text-sm"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        NAE Privacy Policy
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default App;
