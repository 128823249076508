import React, { useEffect, useState } from "react";
import * as d3 from "d3";

export default function Team() {
    const [staff, setStaff] = useState([]);

    useEffect(() => {
        d3.csv(
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vScqq2hmyQt6FIkIZtEDk7BqFA43eHe0mT7y40WAJjT1pgF9vHgMYHI_1sVVMyeZIfA3cJXTlwbceTX/pub?gid=0&single=true&output=csv",
            d3.autoType
        ).then((data) => {
            setStaff(data);
        });
    }, []);
    return (
        <div className="mt-10 container max-w-5xl mx-auto">
            <h1 className="font-montserrat mt-14 text-4xl mb-4 font-bold">
                Metacognition Study Project Team
            </h1>
            <section className="my-16">
                <h2 className="text-3xl text-[#003057] font-semibold text-center mb-4">
                    Research Staff
                </h2>
                <hr className="w-1/2 border-t-4 border-[#003057] mx-auto mt-4 mb-10" />

                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 ">
                    {staff
                        .filter((d) => d.RoleCategory === "Research Staff")
                        .map((person, i) => (
                            <div key={i} className="bg-white overflow-hidden ">
                                <div className="relative overflow-hidden flex justify-center items-center">
                                    <img
                                        className="inset-0 h-1/2 w-1/2 object-cover rounded-full"
                                        src={person["Direct Link"]}
                                        alt={person["Name"]}
                                    />
                                </div>
                                <div className="p-2">
                                    <h3 className="text-lg font-semibold">
                                        {person["Name"]}
                                    </h3>
                                    <p className="mt-1">{person["Title"]}</p>
                                    <p className="mt-1">
                                        {person["Institution"]}
                                    </p>
                                    <p className="mt-1">
                                        Email: {person["Email"]}
                                    </p>
                                    <p className="mt-1">
                                        Website:{" "}
                                        <a
                                            href={person["Website1"]}
                                            className="text-blue-500 hover:text-blue-700"
                                        >
                                            Link
                                        </a>
                                    </p>
                                    <p className="mt-1 text-base text-left">
                                        {person["Background"]}
                                    </p>
                                </div>
                            </div>
                        ))}
                </div>
            </section>
        </div>
    );
}
